import { handleRequest } from "../../utils/axios";

export const findLicenseTypesAPI = async (data) => {
  const config = {
    url: '/license-type/search',
    method: 'POST',
    data
  };
  return handleRequest(config);
};

export const getLicenseTypeByIdAPI = async (id) => {
  const config = {
    url: `/license-type/${id}`,
    method: 'GET'
  };
  return handleRequest(config);
};

export const createLicenseTypesAPI = async (data) => {
  const config = {
    url: '/license-type/',
    method: 'POST',
    data
  };

  return handleRequest(config);
};

export const updateLicenseTypeAPI = async (data) => {
  const config = {
    url: '/license-type/',
    method: 'PUT',
    data
  };

  return handleRequest(config);
};

export const deleteLicenseTypeAPI = async (id) => {
  const config = {
    url: `/license-type/${id}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};

export const deleteLicenseTypesAPI = async (ids) => {
  const config = {
    url: `/license-type/all/${ids.toString()}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};