import { handleRequest } from '../../utils/axios';

export const findExamAPI = async (data) => {
  const config = {
    url: '/qexam/search',
    method: 'POST',
    data,
  };
  return handleRequest(config);
};

export const getExamByIdAPI = async (id) => {
  const config = {
    url: `/qexam/${id}`,
    method: 'GET',
  };
  return handleRequest(config);
};

export const createExamAPI = async (data) => {
  const config = {
    url: '/qexam/',
    method: 'POST',
    data,
  };

  return handleRequest(config);
};

export const updateExamAPI = async (data) => {
  const config = {
    url: '/qexam/',
    method: 'PUT',
    data,
  };

  return handleRequest(config);
};

export const deleteExamAPI = async (id) => {
  const config = {
    url: `/qexam/${id}`,
    method: 'DELETE',
  };
  return handleRequest(config);
};

export const deleteExamsAPI = async (ids) => {
  const config = {
    url: `/qexam/all/${ids.toString()}`,
    method: 'DELETE',
  };
  return handleRequest(config);
};
