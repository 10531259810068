import { handleRequest } from '../../utils/axios';

export const findQuestionCategoryAPI = async (data) => {
  const config = {
    url: '/qcategory/search',
    method: 'POST',
    data,
  };
  return handleRequest(config);
};

export const getQuestionCategoryByIdAPI = async (id) => {
  const config = {
    url: `/qcategory/${id}`,
    method: 'GET',
  };
  return handleRequest(config);
};

export const createQuestionCategoryAPI = async (data) => {
  const config = {
    url: '/qcategory/',
    method: 'POST',
    data,
  };

  return handleRequest(config);
};

export const updateQuestionCategoryAPI = async (data) => {
  const config = {
    url: '/qcategory/',
    method: 'PUT',
    data,
  };

  return handleRequest(config);
};

export const deleteQuestionCategoryAPI = async (id) => {
  const config = {
    url: `/qcategory/${id}`,
    method: 'DELETE',
  };
  return handleRequest(config);
};

export const deleteQuestionCategoriesAPI = async (ids) => {
  const config = {
    url: `/qcategory/all/${ids.toString()}`,
    method: 'DELETE',
  };
  return handleRequest(config);
};
