import { handleRequest } from '../../utils/axios';

export const findQuestionGroupAPI = async (data) => {
  const config = {
    url: '/qgroup/search',
    method: 'POST',
    data,
  };
  return handleRequest(config);
};

export const getQuestionGroupByIdAPI = async (id) => {
  const config = {
    url: `/qgroup/${id}`,
    method: 'GET',
  };
  return handleRequest(config);
};

export const createQuestionGroupAPI = async (data) => {
  const config = {
    url: '/qgroup/',
    method: 'POST',
    data,
  };

  return handleRequest(config);
};

export const updateQuestionGroupAPI = async (data) => {
  const config = {
    url: '/qgroup/',
    method: 'PUT',
    data,
  };

  return handleRequest(config);
};

export const deleteQuestionGroupAPI = async (id) => {
  const config = {
    url: `/qgroup/${id}`,
    method: 'DELETE',
  };
  return handleRequest(config);
};

export const deleteQuestionGroupsAPI = async (ids) => {
  const config = {
    url: `/qgroup/all/${ids.toString()}`,
    method: 'DELETE',
  };
  return handleRequest(config);
};
