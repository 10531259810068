import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import mediaCacheReducer from './slices/gplx/gplx.cache';
import categoryReducer from './slices/gplx/gplx.category';
import examReducer from './slices/gplx/gplx.exam';
import licenseTypeReducer from './slices/gplx/gplx.licenseType';
import notificationReducer from './slices/gplx/gplx.notification';
import practicalExamReducer from './slices/gplx/gplx.practicalExam';
import mediaPrivilegeReducer from './slices/gplx/gplx.privilege';
import mediaRoleReducer from './slices/gplx/gplx.role';
import settingReducer from './slices/gplx/gplx.setting';
import situationReducer from './slices/gplx/gplx.situation';
import mediaUserReducer from './slices/gplx/gplx.user';
import answerReducer from './slices/quiz/quiz.answer';
import qCategoryReducer from './slices/quiz/quiz.category';
import examQuizReducer from './slices/quiz/quiz.exam';
import qGroupReducer from './slices/quiz/quiz.qGroup';
import questionReducer from './slices/quiz/quiz.question';
import affiliateReducer from './slices/shop/shop.affiliate';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error']
};

const rootReducer = combineReducers({
  mediaCache: persistReducer({ ...rootPersistConfig, key: "gplx-cache" }, mediaCacheReducer),
  mediaPrivilege: persistReducer({ ...rootPersistConfig, key: "gplx-privilege" }, mediaPrivilegeReducer),
  mediaRole: persistReducer({ ...rootPersistConfig, key: "gplx-role" }, mediaRoleReducer),
  mediaUser: persistReducer({ ...rootPersistConfig, key: "gplx-user" }, mediaUserReducer),
  situation: persistReducer({ ...rootPersistConfig, key: "gplx-situation" }, situationReducer),
  category: persistReducer({ ...rootPersistConfig, key: "gplx-category" }, categoryReducer),
  exam: persistReducer({ ...rootPersistConfig, key: "gplx-exam" }, examReducer),
  licenseType: persistReducer({ ...rootPersistConfig, key: "gplx-license" }, licenseTypeReducer),
  practicalExam: persistReducer({ ...rootPersistConfig, key: "gplx-practical" }, practicalExamReducer),
  question: persistReducer({ ...rootPersistConfig, key: "quiz-question" }, questionReducer),
  answer: persistReducer({ ...rootPersistConfig, key: "quiz-answer" }, answerReducer),
  qGroup: persistReducer({ ...rootPersistConfig, key: 'quiz-question-group' }, qGroupReducer),
  qCategory: persistReducer({ ...rootPersistConfig, key: 'quiz-question-category' }, qCategoryReducer),
  examQ: persistReducer({ ...rootPersistConfig, key: 'quiz-exam' }, examQuizReducer),
  setting: persistReducer({ ...rootPersistConfig, key: 'gplx-setting' }, settingReducer),
  notification: persistReducer({ ...rootPersistConfig, key: 'gplx-notification' }, notificationReducer),
  affiliate: persistReducer({ ...rootPersistConfig, key: 'gplx-affiliate' }, affiliateReducer)
});

export { rootPersistConfig, rootReducer };
