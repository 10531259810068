import { handleRequest } from '../../utils/axios';

export const searchAnswerAPI = async (data) => {
  const config = {
    url: '/answer/search',
    method: 'POST',
    data,
  };
  return handleRequest(config);
};

export const deleteAnswerAPI = async (id) => {
  const config = {
    url: `/answer/${id}`,
    method: 'DELETE',
  };
  return handleRequest(config);
};

export const deleteAnswersAPI = async (ids) => {
  const config = {
    url: `/answer/all/${ids.toString()}`,
    method: 'DELETE',
  };
  return handleRequest(config);
};

export const getAnswerByIdAPI = async (id) => {
  const config = {
    url: `/answer/${id}`,
    method: 'get',
  };
  return handleRequest(config);
};

export const createAnswerAPI = async (answer) => {
  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: '/answer/',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: answer,
  };
  return handleRequest(config);
};

export const updateAnswerAPI = async (answer) => {
  const config = {
    method: 'put',
    maxBodyLength: Infinity,
    url: '/answer/',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: answer,
  };
  return handleRequest(config);
};