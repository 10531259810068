import { handleRequest } from '../../utils/axios';

export const searchQuestionAPI = async (data) => {
  const config = {
    url: '/question/search',
    method: 'POST',
    data,
  };
  return handleRequest(config);
};

export const deleteQuestionAPI = async (id) => {
  const config = {
    url: `/question/${id}`,
    method: 'DELETE',
  };
  return handleRequest(config);
};

export const deleteQuestionsAPI = async (ids) => {
  const config = {
    url: `/question/all/${ids.toString()}`,
    method: 'DELETE',
  };
  return handleRequest(config);
};

export const getQuestionByIdAPI = async (id) => {
  const config = {
    url: `/question/${id}`,
    method: 'get',
  };
  return handleRequest(config);
};

export const createQuestionAPI = async (question) => {
  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: '/question/',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: question,
  };
  return handleRequest(config);
};

export const updateQuestionAPI = async (question) => {
  const config = {
    method: 'put',
    maxBodyLength: Infinity,
    url: '/question/',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: question,
  };
  return handleRequest(config);
};