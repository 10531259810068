import { createSlice } from '@reduxjs/toolkit';
import { findNotificationsAPI, getNotificationByIdAPI } from '../../../service/gplx/gplx.notification.service';
// utils

const initialState = {
  isLoading: false,
  error: null,
  totalElements: 0,
  totalPages: 0,
  numberOfElements: 0,
  notifications: [],
  notification: null,
  search: {
    page: 0,
    size: 10,
    value: '',
    orders: [
      {
        order: 'desc',
        property: 'createdAt',
      },
    ],
  },
};

const slice = createSlice({
  name: 'gplxNotification',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setNotifications(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.notifications = response.data;
      state.totalElements = response.totalElements;
      state.totalPages = response.totalPages;
      state.numberOfElements = response.numberOfElements;
    },
    setNotification(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.notification = response.data;
    },
    setNotificationSearch(state, action) {
      state.isLoading = false;
      state.search = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const { setNotificationSearch } = slice.actions;
// ----------------------------------------------------------------------

export function getNotifications() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    // read state from rootReducer
    const { notification } = getState();

    const resp = await findNotificationsAPI({ ...notification.search, value: `%${notification.search.value}%` });

    if (resp.code === '200') dispatch(slice.actions.setNotifications(resp));
    else dispatch(slice.actions.hasError(resp));
  };
}

export function getNotification(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const resp = await getNotificationByIdAPI(id);
    if (resp.code === '200') dispatch(slice.actions.setNotification(resp));
    else dispatch(slice.actions.hasError(resp));
  };
}
