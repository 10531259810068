import { lazy, Suspense } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// ----------------------------------------------------------------------
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

const AffiliateList = Loadable(lazy(() => import('../pages/gplx/affiliate/AffiliateList')));
const AffiliateCreate = Loadable(lazy(() => import('../pages/gplx/affiliate/AffiliateCreate')));

const shopRoute = {
  path: 'shop',
  element: (
    <RoleBasedGuard accessibleRoles={['ROLE_ADMIN', 'ROLE_SHOP']}>
      <Outlet />
    </RoleBasedGuard>
  ),
  children: [
    { element: <Navigate to="/dashboard/shop/affiliates" replace />, index: true },

    // affiliate
    { path: 'affiliate/new', element: <AffiliateCreate /> },
    { path: 'affiliates', element: <AffiliateList /> },
    { path: 'affiliate/:id/edit', element: <AffiliateCreate /> },
    { path: 'affiliate/:id/view', element: <AffiliateCreate /> },
  ],
};

export default shopRoute;
