import { lazy, Suspense } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// ----------------------------------------------------------------------
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

// media
const QuestionList = Loadable(lazy(() => import('../pages/quiz/question/QuestionList')));
const QuestionCreate = Loadable(lazy(() => import('../pages/quiz/question/QuestionCreate')));
const ExamList = Loadable(lazy(() => import('../pages/quiz/exam/ExamList')));
const ExamCreate = Loadable(lazy(() => import('../pages/quiz/exam/ExamCreate')));
const QCategoryList = Loadable(lazy(() => import('../pages/quiz/question-category/QCategoryList')));
const QCategoryCreate = Loadable(lazy(() => import('../pages/quiz/question-category/QCategoryCreate')));
const QGroupList = Loadable(lazy(() => import('../pages/quiz/question-group/QGroupList')));
const QGroupCreate = Loadable(lazy(() => import('../pages/quiz/question-group/QGroupCreate')));
const AnswerList = Loadable(lazy(() => import('../pages/quiz/answer/AnswerList')));
const AnswerCreate = Loadable(lazy(() => import('../pages/quiz/answer/AnswerCreate')));

const quizRoute = {
  path: 'quiz',
  element: (
    <RoleBasedGuard accessibleRoles={['ROLE_ADMIN']}>
      <Outlet />
    </RoleBasedGuard>
  ),
  children: [
    { element: <Navigate to="/dashboard/quiz/questions" replace />, index: true },

    { path: 'question/new', element: <QuestionCreate /> },
    { path: 'questions', element: <QuestionList /> },
    { path: 'question/:id/edit', element: <QuestionCreate /> },
    { path: 'question/:id/view', element: <QuestionCreate /> },

    { path: 'answer/new', element: <AnswerCreate /> },
    { path: 'answers', element: <AnswerList /> },
    { path: 'answer/:id/edit', element: <AnswerCreate /> },
    { path: 'answer/:id/view', element: <AnswerCreate /> },

    { path: 'exam/new', element: <ExamCreate /> },
    { path: 'exams', element: <ExamList /> },
    { path: 'exam/:id/edit', element: <ExamCreate /> },
    { path: 'exam/:id/view', element: <ExamCreate /> },

    { path: 'q-category/new', element: <QCategoryCreate /> },
    { path: 'q-categories', element: <QCategoryList /> },
    { path: 'q-category/:id/edit', element: <QCategoryCreate /> },
    { path: 'q-category/:id/view', element: <QCategoryCreate /> },

    { path: 'q-group/new', element: <QGroupCreate /> },
    { path: 'q-groups', element: <QGroupList /> },
    { path: 'q-group/:id/edit', element: <QGroupCreate /> },
    { path: 'q-group/:id/view', element: <QGroupCreate /> },
  ],
};

export default quizRoute;
